import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		statics: {},
		advanceStatics: {},
	},

	actions: {
		fetchAll({ commit }, { fromDate, toDate }) {
			return axios.get('/statics', { params: { fromDate, toDate } })
				.then(({ data }) => {
					const storeData = {
						disabledProduct: data.disabled_product,
						newsCount: data.news_count,
						popularProduct: data.popular_product,
						productCount: data.product_count,
						productsView: data.products_view,
						recentProducts: data.recent_add_products,
						userCount: data.user_count,
					}
					commit(types.STORE_STATICS, storeData);
					return storeData;
				})
		},
		fetchAllAdvance({ commit }, { fromDate, toDate }) {
			return axios.get('/statics-chart', { params: { fromDate, toDate } })
				.then(({ data }) => {
					commit(types.STORE_ADVANCE_STATICS, data);
					return data;
				})
		},
		fetchCashBack({ commit, state }, { fromDate, toDate }) {
			return axios.get('/statics/cash-back', { params: { fromDate, toDate } })
				.then(({ data }) => {
					// state.statics = { ...state.statics, ...data }
					return 0;
				})
		},
	},

	mutations: {
		[types.STORE_STATICS](state, statics) {
			state.statics = statics
		},
		[types.STORE_ADVANCE_STATICS](state, advanceStatics) {
			// state.advanceStatics = advanceStatics;
		}
	}
}