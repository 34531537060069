<template>
	<v-form ref="form" @submit.prevent="submit" class="px-2">
		<!-- lang tabs -->
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:disable-non-selected="loading || submitLoading"
			class="mb-2"
			@change="updateFieldsFromDraft"
		/>

		<!-- inputs -->
		<v-row class="mt-0 mb-5">
			<!-- input data -->
			<v-col
				:lg="useUploadBranchImage ? 9 : 12"
				:md="useUploadBranchImage ? 8 : 12"
				cols="12"
				order-md="0"
				order="1"
				class="pb-md-0"
			>
				<v-row dense class="mt-0 mb-2">
					<!-- name -->
					<v-col lg="6" cols="12">
						<v-text-field
							v-model="name"
							:label="$t('inputs.name')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="rules.required"
						></v-text-field>
					</v-col>

					<!-- country-code -->
					<v-col lg="6" cols="12" v-if="branchInfo.countryCode">
						<v-text-field
							v-model="countryCode"
							:label="$t('inputs.country-code')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>

					<!-- money-code -->
					<v-col lg="6" cols="12" v-if="branchInfo.moneyCode">
						<v-text-field
							v-model="moneyCode"
							:label="$t('inputs.money-code')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>

					<!-- address -->
					<v-col lg="6" cols="12" v-if="branchInfo.address">
						<v-text-field
							v-model="address"
							:label="$t('inputs.address')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>

					<!-- opening hours -->
					<v-col lg="6" cols="12" v-if="branchInfo.openingHours">
						<v-text-field
							v-model="openHours"
							:label="$t('inputs.opening-hours')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>

					<!-- location -->
					<v-col lg="6" cols="12" v-if="branchInfo.location">
						<v-text-field
							v-model="location"
							:label="$t('inputs.location')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="location ? rules.url : []"
						></v-text-field>
					</v-col>

					<!-- is-main -->
					<v-col lg="6" cols="12" class="d-flex flex-sm-row flex-column align-sm-center">
						<v-switch
							v-model="isMain"
							:label="$t('inputs.is-main-branch')"
							hide-details
							outlined
							class="mt-sm-0 ms-4 pt-0"
							:disabled="loading || submitLoading"
						></v-switch>
					</v-col>		
				</v-row>

				<div class="text-body-1 font-weight-bold text-capitalize mb-3">
					{{$t('Branches.contact-info')}}
				</div>

				<v-row dense>
					<!-- email -->
					<v-col lg="6" cols="12" v-if="contacts.email">
						<v-text-field
							v-model="email"
							:label="$t('inputs.email')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="email ? rules.email : []"
						></v-text-field>
					</v-col>
					
					<!-- phone -->
					<v-col lg="6" cols="12" v-if="contacts.phone">
						<v-text-field
							v-model="phone"
							:label="$t('inputs.phone')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>
					
					<!-- mobile -->
					<v-col lg="6" cols="12" v-if="contacts.mobile">
						<v-text-field
							v-model="mobile"
							:label="$t('inputs.mobile')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="mobile ? rules.mobile : []"
						></v-text-field>
					</v-col>
					
					<!-- whatsapp -->
					<v-col lg="6" cols="12" v-if="contacts.whatsapp">
						<v-text-field
							v-model="whatsapp"
							:label="$t('inputs.whatsapp')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="whatsapp ? rules.mobile : []"
						></v-text-field>
					</v-col>

					<!-- telegram -->
					<v-col lg="6" cols="12" v-if="contacts.telegram">
						<v-text-field
							v-model="telegram"
							:label="$t('inputs.telegram')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="telegram ? rules.url : []"
						></v-text-field>
					</v-col>

					<!-- telegram channel -->
					<v-col lg="6" cols="12" v-if="contacts.telegramChannel">
						<v-text-field
							v-model="telegramChannel"
							:label="$t('inputs.telegram-channel')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="telegramChannel ? rules.url : []"
						></v-text-field>
					</v-col>
					
					<!-- facebook -->
					<v-col lg="6" cols="12" v-if="contacts.facebook">
						<v-text-field
							v-model="facebook"
							:label="$t('inputs.facebook')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="facebook ? rules.url : []"
						></v-text-field>
					</v-col>

					<!-- instagram -->
					<v-col lg="6" cols="12" v-if="contacts.instagram">
						<v-text-field
							v-model="instagram"
							:label="$t('inputs.instagram')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="instagram ? rules.url : []"
						></v-text-field>
					</v-col>

					<!-- twitter -->
					<v-col lg="6" cols="12" v-if="contacts.twitter">
						<v-text-field
							v-model="twitter"
							:label="$t('inputs.twitter')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="twitter ? rules.url : []"
						></v-text-field>
					</v-col>

					<!-- youtube -->
					<v-col lg="6" cols="12" v-if="contacts.youtube">
						<v-text-field
							v-model="youtube"
							:label="$t('inputs.youtube')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="youtube ? rules.url : []"
						></v-text-field>
					</v-col>

					<!-- linkedIn -->
					<v-col lg="6" cols="12" v-if="contacts.linkedIn">
						<v-text-field
							v-model="linkedIn"
							:label="$t('inputs.linkedIn')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="linkedIn ? rules.url : []"
						></v-text-field>
					</v-col>

					<!-- pinterest -->
					<v-col lg="6" cols="12" v-if="contacts.pinterest">
						<v-text-field
							v-model="pinterest"
							:label="$t('inputs.pinterest')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="pinterest ? rules.url : []"
						></v-text-field>
					</v-col>
				</v-row>
			</v-col>

			<template v-if="useUploadBranchImage">
				<v-col cols="auto" class="d-md-block d-none px-1">
					<v-divider vertical/>
				</v-col>
				
				<!-- drop image -->
				<v-col md cols="12" class="pb-md-0">
					<div class="sticky">
						<div class="text-body-1 font-weight-bold mb-3">
							{{$t('upload-images')}}
						</div>
						<bee-drop-img
							v-model="mainImage"
							dashed
							border-width="2"
							:border-color="isSubmitted && !mainImage ? '#f00' : '#ccc'"
							radius="11"
							height="250px"
							blurable
							clearable
							:loading="loading"
							:disabled="loading || submitLoading"
							:hide-image-name="false"
							disable-file-picker
							@remove-image="fileManagerFiles.mainImage = {}"
							@click="$eventBus.$emit('file-manager', { images: true }, (url, file) => {
								mainImage = url;
								fileManagerFiles.mainImage = file;
							})"
						>
							<template v-slot:drag-text>
								<span class="text-body-1 font-weight-medium">
									{{$t('drag-text')}}
								</span>
								<span class="text-caption mb-0">
									{{$t('supported-text')}}
								</span>
							</template>
						</bee-drop-img>
						
						<bee-drop-zone
							v-if="uploadMoreImages"
							v-model="images"
							dashed
							drop-zone-classes="pa-3"
							radius="11"
							border-width="2"
							max-height="250"
							:drop-image-props="{
								width: 70,
								height: 70
							}"
							:clear-icon="$route.params.id ? 'mdi-delete' : 'mdi-close'"
							:clear-color="$route.params.id ? 'red' : null"
							:gap-size="4"
							:drop-menu-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
							:drop-menu-max-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
							:drop-menu-left="null"
							show-add-btn
							show-drop-menu
							no-drop-replace
							class="mt-3"
							:loading="loading"
							:disabled="loading || submitLoading"
							disable-file-picker
							@remove-image="removeImage"
							@click="$eventBus.$emit('file-manager', { images: true, multiple: true }, (urls, files) => {
								images.push(...urls);
								fileManagerFiles.moreImages.push(...files);
							})"
						>
							<template v-slot:drag-text>
								<p class="mb-0">
									<span class="d-block text-body-1 font-weight-bold">
										{{$t('upload-more-image')}}
									</span>
									<span class="d-block text-caption mb-0">{{$t('drag-text')}}</span>
								</p>
							</template>
						</bee-drop-zone>
					</div>
				</v-col>
			</template>
		</v-row>
		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium me-2"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
		<v-btn
			color="white"
			class="rounded-medium"
			:to="{ name: backRouteName }"
			:disabled="loading || submitLoading"
		>
			{{$t('back')}}
		</v-btn>
	</v-form>
</template>

<script>
import rules from '@/helpers/validation rules'
import { branches } from '@/configs/routes.config';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import { branchInfo, contacts, useUploadBranchImage, useUploadMoreImages } from '@/configs/global.config';
import langsDraft from '@/mixins/langs-draft';

export default {
	name: 'Branch',
	mixins: [langsDraft],
	
	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		isSubmitted: false,
		productImages: [],
		lang: defaultLang,
		backRouteName: branches.name,
		rules,

		// configs
		multiLangInputs,
		branchInfo,
		contacts,
		useUploadBranchImage,
		uploadMoreImages: useUploadMoreImages.branches,

		// file manager
		fileManagerFiles: {
			mainImage: {},
			moreImages: [],
		},

		// draft for languages 
		draft: {},
		draftNames: [
			'name',
			'openHours',
			'countryCode',
			'moneyCode',
		],
		
		// data
		name: null,
		isMain: null,
		mainImage: null,
		images: [],

		openHours: null,
		countryCode: null,
		moneyCode: null,
		location: null,
		
		email: null,
		address: null,
		phone: null,
		mobile: null,
		whatsapp: null,
		facebook: null,
		telegram: null,
		telegramChannel: null,
		instagram: null,
		twitter: null,
		youtube: null,
		linkedIn: null,
		pinterest: null,
	}),

	watch: {
		$route(newVal) {
			if (newVal.params.id) {
				this.loading = true;
				this.fetchBranches().finally(() => {
					this.loading = false;
				});
			}
		}
	},

	methods: {
		async submit() {
			this.isSubmitted = true;
			const image = useUploadBranchImage ? this.mainImage : true;
			if (this.$refs.form.validate() && image) {
				this.submitLoading = true;
				// more images
				const images = this.images.filter(c => typeof c !== 'string');

				const promises = [];
				let imagesPromise = null;
				let itemData = null;

				const submitRequest = (
					isDefaultOne,
					{ name, openHours, countryCode, moneyCode, lang },
					id = this.$route.params.id
				) => {
					const actionName = id ? 'update' : 'create';
					return this.$store.dispatch(`branches/${actionName}`, {
						id: id,
						name: name,
						openHours: openHours,
						countryCode: countryCode,
						moneyCode: moneyCode,
						isMain: this.isMain,
						image:
							isDefaultOne && typeof this.mainImage !== 'string'
								? this.mainImage
								: null,
						imagePath:
							isDefaultOne && typeof this.mainImage === 'string'
								? this.fileManagerFiles.mainImage.publicPath
								: null,
						imageName:
							isDefaultOne && typeof this.mainImage === 'string'
								? this.fileManagerFiles.mainImage.fileName
								: null,
						email: this.email,
						address: this.address,
						phone: this.phone,
						mobile: this.mobile,
						whatsapp: this.whatsapp,
						facebook: this.facebook,
						telegram: this.telegram,
						telegramChannel: this.telegramChannel,
						twitter: this.twitter,
						youtube: this.youtube,
						linkedIn: this.linkedIn,
						pinterest: this.pinterest,
						instagram: this.instagram,
						location: this.location,
						acceptLanguage: lang
					}).then((data) => {
						if (isDefaultOne && (images.length || this.fileManagerFiles.moreImages.length)) {
							imagesPromise = this.$store.dispatch('branches/uploadImages', {
								id: data.id,
								images,
								imagesPaths: this.fileManagerFiles.moreImages.map(c => c.publicPath),
								imagesNames: this.fileManagerFiles.moreImages.map(c => c.fileName),
							})
						}
						return data;
					})
				};

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const name = this.draft.name[lang];
					const openHours = this.draft.openHours[lang];
					const countryCode = this.draft.countryCode[lang];
					const moneyCode = this.draft.moneyCode[lang];

					const dataDueLang = { name, openHours, countryCode, moneyCode, lang };

					if (name) {
						if (index === 0) {
							await submitRequest(index === 0, dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(false, dataDueLang, itemData.id));
						}
					}
				}

				const successHandler = () => {
					const message =
						this.$route.params.id
							? this.$t('$-is-updated-successfully', { name: this.$t('Branches.branch') })
							: this.$t('$-is-created-successfully', { name: this.$t('Branches.branch') });
					this.$eventBus.$emit('show-snackbar', false, message);
					this.$router.push({ name: branches.name });
				}
				const errorHandler = () => {
					if (itemData) {
						this.$router.replace({
							name: this.$route.name,
							params: { slug: itemData.slug, id: itemData.id }
						});
					}
				}

				Promise.all(promises).then(() => {
					if (!imagesPromise) successHandler();
					else {
						this.$eventBus.$emit('show-snackbar', false, this.$t('please-wait-uploading-the-images'));
						imagesPromise.catch(errorHandler).then(successHandler);
					}
				}).catch(
					errorHandler
				).finally(() => {
					if (!imagesPromise) this.submitLoading = false;
					else imagesPromise.finally(() => { this.submitLoading = false });
				})
			}
		},

		// FIXME show dialog to confirm remove
		removeImage(image) {
			if (this.$route.params.id) {
				const imageId = this.productImages.find(c => c.url === image)?.id;
				
				if (imageId) {
					this.$store.dispatch('branches/deleteImage', {
						id: this.$route.params.id,
						imageId: imageId
					})
				}
			}

			const fileManagerImageId = this.fileManagerFiles.moreImages.find(c => c.url === image)?.id;
			if (fileManagerImageId) {
				const imageIndex = this.fileManagerFiles.moreImages.findIndex(c => c.id === fileManagerImageId);
				this.fileManagerFiles.moreImages.splice(imageIndex, 1);
			}
		},

		fetchBranches() {
			const promises = [];

			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('branches/fetchBySlug', {
						slug: this.$route.params.slug,
						acceptLanguage: c.key
					}).then((data) => {
						if (index === 0) {
							this.isMain = data.isMain;
							this.mainImage = data.primaryImage.url;
							this.images = data.images.map(c => c.url).filter(c => c !== data.primaryImage.url);

							this.email = data.contacts.email;
							this.address = data.contacts.address;
							this.phone = data.contacts.phone;
							this.mobile = data.contacts.mobile;
							this.whatsapp = data.contacts.whatsapp;
							this.facebook = data.contacts.facebook;
							this.telegram = data.contacts.telegram;
							this.telegramChannel = data.contacts.telegramChannel;
							this.instagram = data.contacts.instagram;
							this.twitter = data.contacts.twitter;
							this.youtube = data.contacts.youtube;
							this.linkedIn = data.contacts.linkedIn;
							this.pinterest = data.contacts.pinterest;
							this.location = data.contacts.location;

							this.productImages = data.images.filter(c => c !== data.primaryImage.url);
						}

						this.draft.name[c.key] = data.name;
						this.draft.openHours[c.key] = data.openHours;
						this.draft.countryCode[c.key] = data.countryCode;
						this.draft.moneyCode[c.key] = data.moneyCode;
					})
				);
			});
			
			return Promise.all(promises).then(() => {
				this.name = this.draft.name[this.lang];
				this.openHours = this.draft.openHours[this.lang];
				this.countryCode = this.draft.countryCode[this.lang];
				this.moneyCode = this.draft.moneyCode[this.lang];
			});
		}
	},

	created() {
		if (this.$route.params.id) {
			this.loading = true;
			this.fetchBranches().finally(() => {
				this.loading = false;
			})
		}
	}
};
</script>

<style lang="scss">
</style>