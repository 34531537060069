<template>
	<v-form ref="form" @submit.prevent="submit" class="px-2">
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:disable-non-selected="loading || submitLoading"
			class="mb-2"
			@change="updateFieldsFromDraft"
		/>

		<!-- inputs -->
		<v-row dense class="align-center mt-0 mb-5">
			<!-- product-name -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					v-model="name"
					:label="$t('inputs.plan-name')"
					hide-details
					outlined
					dense
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					:rules="rules.required"
				></v-text-field>
			</v-col>

			<!-- duration -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					v-model.number="duration"
					:label="$t('inputs.duration')"
					hide-details
					outlined
					dense
					type="number"
					hide-spin-buttons
					min="0"
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					:rules="rules.requiredAsNumber"
				></v-text-field>
			</v-col>

			<!-- price -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					v-model.number="price"
					:label="$t('inputs.price')"
					hide-details
					outlined
					dense
					type="number"
					hide-spin-buttons
					min="0"
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
					:rules="rules.requiredAsNumber"
				></v-text-field>
			</v-col>

			<!-- is featured -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-switch
					v-model="isFeatured"
					:label="$t('inputs.is-featured')"
					hide-details
					outlined
					dense
					class="rounded-medium ma-0"
					:disabled="loading || submitLoading"
				></v-switch>
			</v-col>

			<!-- offer price -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-text-field
					v-model.number="offerPrice"
					:label="$t('inputs.offer-price')"
					hide-details
					outlined
					dense
					class="rounded-medium"
					:loading="loading"
					:disabled="loading || submitLoading"
				></v-text-field>
			</v-col>

			<!-- offer start date -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-menu
					ref="offerStartDateMenu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="offerStartDate"
							:label="$t('inputs.offer-start-date')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							prepend-inner-icon="mdi-calendar"
							readonly
							clearable
							:loading="loading"
							:disabled="loading || submitLoading"
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="offerStartDate"
						:max="(new Date().getFullYear() + 1) + new Date().toISOString().slice(4, 10)"
						min="1950-01-01"
						@change="$refs.offerStartDateMenu.save(offerStartDate)"
					></v-date-picker>
				</v-menu>
			</v-col>

			<!-- offer-end-date -->
			<v-col lg="3" md="4" sm="6" cols="12">
				<v-menu
					ref="offerEndDateMenu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					min-width="auto"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="offerEndDate"
							:label="$t('inputs.offer-end-date')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							prepend-inner-icon="mdi-calendar"
							readonly
							clearable
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="[() => !Boolean(offerEndDate) || moment(offerEndDate).isAfter(offerStartDate)]"
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker
						v-model="offerEndDate"
						:max="(new Date().getFullYear() + 1) + new Date().toISOString().slice(4, 10)"
						min="1950-01-01"
						@change="$refs.offerEndDateMenu.save(offerEndDate);"
					></v-date-picker>
				</v-menu>
			</v-col>
		</v-row>

		<!-- description -->
		<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('inputs.description')}}</div>
		<text-editor
			v-model="description"
			:error="!Boolean(description) && isSubmitted"
			:loading="loading"
			:use-template-builder="allowTemplateBuilder"
			class="mb-5"
		/>

		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium me-2"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
		<v-btn
			color="white"
			class="rounded-medium"
			:to="{ name: backRouteName }"
			:disabled="loading || submitLoading"
		>
			{{$t('back')}}
		</v-btn>
	</v-form>
</template>

<script>
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config'
import { plans } from '@/configs/routes.config'
import rules from '@/helpers/validation rules'
import moment from 'moment'
import langsDraft from '@/mixins/langs-draft'
import { allowTemplateBuilder } from '@/configs/global.config'

export default {
	name: 'Plan',
	mixins: [langsDraft],

	data: () => ({
		// helpers
		loading: false,
		submitLoading: false,
		lang: defaultLang,
		isSubmitted: false,
		backRouteName: plans.name,
		moment,
		rules,
		
		// configs
		multiLangInputs,
		allowTemplateBuilder,

		// draft for languages 
		draft: {},
		draftNames: [
			'name',
			{ name: 'description', value: '' }
		],

		// data
		name: null,
		duration: null,
		price: null,
		isFeatured: null,
		offerPrice: null,
		offerStartDate: null,
		offerEndDate: null,
		description: null
	}),

	watch: {
		$route(newVal) {
			if (newVal.params.id) {
				this.loading = true;
				this.fetchPlan().finally(() => {
					this.loading = false;
				});
			}
		}
	},

	methods: {
		fetchPlan() {
			const promises = [];

			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('plans/fetchBySlug', {
						slug: this.$route.params.slug,
						acceptLanguage: c.key
					}).then((data) => {
						if (index === 0) {
							this.duration = data.duration;
							this.price = data.price;
							this.isFeatured = data.isFeatured;
							this.offerPrice = data.offerPrice;
							this.offerStartDate = data.offerStart ? moment(data.offerStart).format('YYYY-MM-DD') : null;
							this.offerEndDate = data.offerEnd ? moment(data.offerEnd).format('YYYY-MM-DD') : null;
						}
						this.draft.name[c.key] = data.name;
						this.draft.description[c.key] = data.description;
					})
				)
			});

			return Promise.all(promises).then(() => {
				this.name = this.draft.name[this.lang];
				this.description = this.draft.description[this.lang];
			})
		},

		async submit() {
			this.isSubmitted = true;
			if (this.$refs.form.validate() && this.description) {
				this.submitLoading = true;

				const promises = [];
				let itemData = null;

				const submitRequest = (
					{ name, description, lang },
					id = this.$route.params.id
				) => {
					const actionName = id ? 'update' : 'create';
					return this.$store.dispatch(`plans/${actionName}`, {
						id: id,
						name: name,
						duration: this.duration,
						price: this.price,
						offerPrice: this.offerPrice,
						offerStart: this.offerStartDate,
						offerEnd: this.offerEndDate,
						description: description,
						isFeatured: this.isFeatured,
						acceptLanguage: lang
					});
				}

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const name = this.draft.name[lang];
					const description = this.draft.description[lang];

					const dataDueLang = { name, description, lang };

					if (name && description) {
						if (index === 0) {
							await submitRequest(dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(dataDueLang, itemData.id));
						}
					}
				}

				Promise.all(promises).then(() => {
					const message =
						this.$route.params.id
							? this.$t('$-is-updated-successfully', { name: this.$t('Plans.plan') })
							: this.$t('$-is-created-successfully', { name: this.$t('Plans.plan') });
					this.$eventBus.$emit('show-snackbar', false, message);
					this.$router.push({ name: plans.name });
				}).catch(() => {
					if (itemData) {
						this.$router.replace({
							name: this.$route.name,
							params: { slug: itemData.slug, id: itemData.id }
						});
					}
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		},
	},

	created() {
		if (this.$route.params.id) {
			this.loading = true;
			this.fetchPlan().finally(() => {
				this.loading = false;
			});
		} 
	}
}
</script>

<style>

</style>