<template>
	<v-form ref="form" @submit.prevent="submit" class="px-2">
		<!-- up-selling  -->
		<v-dialog
			v-model="upSellingDialog"
			max-width="450"
			content-class="rounded-medium"
		>
			<v-form ref="upSellingForm" @submit.prevent="saveEntry">
				<v-card>
					<v-card-title class="justify-center">
						{{upSellingEntryId ? $t('edit') : $t('add')}} {{$t('Products.entry')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- lang tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="upSellingFormLang"
							background-color="bgColor"
							class="mb-2"
						/>

						<v-row dense align="center">
							<!-- text -->
							<v-col cols="12">
								<v-textarea
									v-model="upSellingData.text[upSellingFormLang]"
									:label="$t('inputs.text')"
									rows="1"
									no-resize
									auto-grow
									dense
									outlined
									hide-details
									:rules="rules.required"
									class="rounded-medium"
								></v-textarea>
							</v-col>

							<!-- cart text -->
							<v-col cols="12">
								<v-textarea
									v-model="upSellingData.cartText[upSellingFormLang]"
									:label="$t('inputs.cart-text')"
									rows="1"
									no-resize
									auto-grow
									dense
									outlined
									hide-details
									:rules="rules.required"
									class="rounded-medium"
								></v-textarea>
							</v-col>
							
							<!-- type -->
							<v-col sm="6" cols="12">
								<v-autocomplete
									v-model="upSellingData.type"
									:label="$t('inputs.type')"
									:items="upSellingEntryTypes"
									item-text="name"
									item-value="id"
									dense
									outlined
									hide-details
									:rules="rules.requiredAsNumber"
									class="rounded-medium"
									@change="
										price = null;
										upSellingData.options = [];
										optionsTitle = createObjectFromLangs();
									"
								></v-autocomplete>
							</v-col>

							<!-- price -->
							<v-col
								v-if="[upSellingEntryType.yesNo, upSellingEntryType.count].includes(upSellingData.type)"
								sm="6"
								cols="12"
							>
								<v-text-field
									v-model="upSellingData.price"
									:label="$t('inputs.price')"
									type="number"
									hide-spin-buttons
									dense
									outlined
									hide-details
									:rules="rules.requiredAsNumber"
									class="rounded-medium"
								></v-text-field>
							</v-col>

							<!-- options title -->
							<v-col
								v-if="upSellingData.type === upSellingEntryType.yesNoMultiChoice"
								sm="6"
								cols="12"
							>
								<v-text-field
									v-model="upSellingData.optionsTitle[upSellingFormLang]"
									:label="$t('inputs.options-title')"
									dense
									outlined
									hide-details
									class="rounded-medium"
								></v-text-field>
							</v-col>

							<!-- is required -->
							<v-col sm="6" cols="12">
								<v-switch
									v-model="upSellingData.isRequired"
									:label="$t('Products.required')"
									:ripple="false"
									hide-details
									dense
									class="mt-0 pt-0"
								></v-switch>
							</v-col>
						</v-row>

						<!-- options -->
						<template
							v-if="[upSellingEntryType.multiChoice, upSellingEntryType.multiple, upSellingEntryType.yesNoMultiChoice].includes(upSellingData.type)"
						>
							<div class="d-flex align-center mt-3 mb-2">
								<span class="text-capitalize text-body-1 black--text text--darken-2 mb-0">
									{{$t('Products.options')}}
								</span>

								<v-btn
									icon
									@click="upSellingData.options.push({
										id: upSellingData.options.length,
										text: createObjectFromLangs(),
										price: null
									})"
								>
									<v-icon dense>mdi-plus-circle-outline</v-icon>
								</v-btn>
							</div>

							<!-- options rows -->
							<v-row v-for="(option, index) in upSellingData.options" :key="option.id" dense>
								<!-- text -->
								<v-col cols="6">
									<v-text-field
										v-model="option.text[upSellingFormLang]"
										:label="$t('inputs.text')"
										dense
										outlined
										hide-details
										:rules="rules.required"
										class="rounded-medium"
									></v-text-field>
								</v-col>

								<!-- price -->
								<v-col cols="5">
									<v-text-field
										v-model.number="option.price"
										:label="$t('inputs.price')"
										type="number"
										hide-spin-buttons
										dense
										outlined
										hide-details
										:rules="rules.requiredAsNumber"
										class="rounded-medium"
									></v-text-field>
								</v-col>

								<!-- remove -->
								<v-col cols="1">
									<v-btn
										icon
										@click="
											upSellingData.options.splice(index, 1);
											upSellingData.options.forEach((c, i, arr) => c.id > index ? arr[i].id--  : null)
										"
									>
										<v-icon dense>mdi-minus-circle-outline</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</template>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn
							type="submit"
							color="primary"
							class="rounded-medium"
						>{{$t('save')}}</v-btn>
						<v-btn
							class="rounded-medium"
							@click="upSellingDialog = false;"
						>{{$t('cancel')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- lang tabs -->
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:disable-non-selected="loading || submitLoading"
			class="mb-2"
			@change="updateFieldsFromDraft"
		/>

		<!-- inputs -->
		<v-row class="mt-0 mb-5">
			<!-- input data -->
			<v-col lg="9" md="8" cols="12" order-md="0" order="1" class="pb-md-0">
				<v-row dense class="align-center mt-0 mb-2">
					<!-- product name -->
					<v-col sm="6" cols="12">
						<v-text-field
							v-model="name"
							:label="$t('inputs.product-name')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="rules.required"
						></v-text-field>
					</v-col>

					<!-- categories && categories tree -->
					<template>
						<v-col v-if="!useCategoryTree" sm="6" cols="12">
							<v-autocomplete
								v-model="categoriesIds"
								:label="$t('inputs.category')"
								:items="categories"
								:item-text="item => getCategoryItemText(item)"
								item-value="id"
								multiple
								hide-details
								outlined
								dense
								chips
								small-chips
								deletable-chips
								class="rounded-medium"
								:loading="loading || assetsLoading"
								:disabled="loading || submitLoading"
								:rules="rules.requiredAsArray"
								@change="initializeAttributesModel()"
							></v-autocomplete>
						</v-col>
						
						<!-- categories tree -->
						<v-col v-else sm="6" cols="12">
							<v-tree-autocomplete
								v-model="categoriesIds"
								:label="$t('inputs.category')"
								:items="categories"
								:treeItems="hierarchyCategories"
								chips
								small-chips
								deletable-chips
								dense
								hide-details
								outlined
								:loading="loading || assetsLoading"
								:disabled="loading || submitLoading"
								input-class="rounded-medium"
								:rules="rules.requiredAsArray"
								@change="initializeAttributesModel()"
							></v-tree-autocomplete>
						</v-col>
					</template>

					<!-- summary -->
					<v-col cols="12">
						<v-textarea
							v-model="summary"
							:label="$t('inputs.summary')"
							auto-grow
							no-resize
							rows="1"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-textarea>
					</v-col>

					<!-- filter & filters tree -->
					<template v-if="showFilters && useFiltersFor.products">
						<!-- filter -->
						<v-col v-if="!useFilterTree" sm="6" cols="12">
							<v-autocomplete
								v-model="filterIds"
								:label="$t('inputs.filter')"
								:items="filters"
								item-text="name"
								item-value="id"
								multiple
								hide-details
								outlined
								dense
								chips
								small-chips
								deletable-chips
								class="rounded-medium"
								:loading="loading || assetsLoading"
								:disabled="loading || submitLoading"
							></v-autocomplete>
						</v-col>

						<!-- filters tree -->
						<v-col v-else sm="6" cols="12">
							<v-tree-autocomplete
								v-model="filterIds"
								:label="$t('inputs.filter')"
								:items="filters"
								:treeItems="hierarchyFilters"
								chips
								small-chips
								deletable-chips
								dense
								hide-details
								outlined
								:loading="loading || assetsLoading"
								:disabled="loading || submitLoading"
								input-class="rounded-medium"
							></v-tree-autocomplete>
						</v-col>
					</template>

					<!-- publishers -->
					<v-col v-if="showPublishers" sm="6" cols="12">
						<v-autocomplete
							v-model="publisherIds"
							:label="$t('inputs.publisher')"
							:items="publishers"
							item-text="name"
							item-value="id"
							multiple
							hide-details
							outlined
							dense
							chips
							small-chips
							deletable-chips
							class="rounded-medium"
							:loading="loading || assetsLoading"
							:disabled="loading || submitLoading"
						></v-autocomplete>
					</v-col>

					<!-- keywords -->
					<v-col v-if="showKeywords" sm="6" cols="12">
						<v-autocomplete
							v-model="keywordIds"
							:label="$t('inputs.keywords')"
							:items="keywords"
							item-text="name"
							item-value="id"
							multiple
							hide-details
							outlined
							dense
							chips
							small-chips
							deletable-chips
							class="rounded-medium"
							:loading="loading || assetsLoading"
							:disabled="loading || submitLoading"
						></v-autocomplete>
					</v-col>
					
					<!-- date-of-publication -->
					<v-col lg="3" sm="6" cols="12">
						<!-- date -->
						<v-menu
							ref="menu"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="date"
									:label="$t('inputs.date')"
									hide-details
									outlined
									dense
									class="rounded-medium"
									prepend-inner-icon="mdi-calendar"
									readonly
									clearable
									:loading="loading"
									:disabled="loading || submitLoading"
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="date"
								:max="(new Date().getFullYear() + 1) + new Date().toISOString().slice(4, 10)"
								min="1950-01-01"
								@change="$refs.menu.save(date)"
							></v-date-picker>
						</v-menu>
					</v-col>

					<!-- is-featured -->
					<v-col md="auto" sm="3">
						<v-switch
							v-model="isFeatured"
							:label="$t('inputs.is-featured')"
							hide-details
							dense
							class="mt-0 pt-0"
							:disabled="loading || submitLoading"
						></v-switch>
					</v-col>

					<!-- is home page -->
					<v-col v-if="useInHomePage" md="auto" sm="3">
						<v-switch
							v-model="inHomePage"
							:label="$t('inputs.is-in-home-page')"
							hide-details
							dense
							class="mt-0 pt-0"
							:disabled="loading || submitLoading"
						></v-switch>
					</v-col>

					<!-- is quantitative -->
					<v-col v-if="showIsQuantitative" md="auto" sm="3">
						<v-switch
							v-model="isQuantitative"
							:label="$t('inputs.is-quantitative')"
							hide-details
							dense
							class="mt-0 pt-0"
							:disabled="loading || submitLoading"
						></v-switch>
					</v-col>

					<!-- file -->
					<v-col
						v-if="showFileUpload"
						:lg="showIsQuantitative && useInHomePage ? 2 : null"
						:md="useInHomePage ? 4 : 5"
						sm="6"
						cols="12"
					>
						<v-file-input
							v-model="file"
							:label="$t('inputs.file')"
							hide-details
							outlined
							prepend-icon
							prepend-inner-icon="$file"
							dense
							class="rounded-medium"
							truncate-length="100"
							:loading="loading"
							:disabled="loading || submitLoading"
							:append-icon="file && uploadedFileUrl ? 'mdi-download' : null"
							@change="uploadedFileUrl = null"
							@click:append="showFile(uploadedFileUrl)"
							@click.prevent="$eventBus.$emit('file-manager', { files: true }, fileCallBack)"
						></v-file-input>
					</v-col>

					<!-- price -->
					<v-col v-if="showPrice" md="auto" sm="6" cols="12" class="flex-grow-1">
						<v-text-field
							v-model.number="price"
							:label="$t('inputs.price')"
							hide-details
							outlined
							dense
							type="number"
							min="0"
							class="rounded-medium"
							hide-spin-buttons
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>

					<!-- subscription price -->
					<v-col v-if="showPlan" md="auto" sm="6" cols="12" class="flex-grow-1">
						<v-text-field
							v-model.number="subscriptionPrice"
							:label="$t('inputs.subscription-price')"
							hide-details
							outlined
							dense
							type="number"
							min="0"
							class="rounded-medium"
							hide-spin-buttons
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>

					<!-- code -->
					<v-col v-if="showCode" :md="useInHomePage ? 4 : 5" sm="6" cols="12">
						<v-text-field
							v-model="code"
							:label="$t('inputs.code')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-text-field>
					</v-col>

					<!-- customized related -->
					<template v-if="useCustomizedRelated">
						<!-- related products -->
						<v-col md="8" cols="12">
							<v-autocomplete
								v-model="relatedProductsIds"
								:label="$t('inputs.related-products')"
								:items="products.filter(c => c.id !== +$route.params.id)"
								item-text="name"
								item-value="id"
								multiple
								hide-details
								chips
								small-chips
								deletable-chips
								outlined
								dense
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
							></v-autocomplete>
						</v-col>
						
						<!-- suggest related from categories -->
						<v-col md="4" cols="12">
							<v-switch
								v-model="suggestRelatedFromCategories"
								:label="$t('inputs.suggest-related-from-categories')"
								hide-details
								dense
								class="mt-0 pt-0"
								:disabled="loading || submitLoading"
							></v-switch>
						</v-col>
					</template>

					<!-- offers -->
					<template v-if="showOffers">
						<v-col
							:md="!showOfferInputs ? 4 : null"
							:sm="!showOfferInputs ? 6 : null"
							cols="12"
						>
							<v-expand-transition mode="out-in">
								<!-- offer -->
								<v-autocomplete
									v-if="!showOfferInputs"
									v-model="code"
									:label="$t('inputs.offer')"
									hide-details
									outlined
									dense
									class="rounded-medium"
									:loading="loading"
									:disabled="loading || submitLoading"
									append-outer-icon="mdi-plus-circle-outline"
									@click:append-outer="showOfferInputs = !showOfferInputs"
								></v-autocomplete>
								
								<!-- offer inputs -->
								<div v-else>
									<div class="d-flex align-center ms-2">
										<span class="text-body-1 text-capitalize me-3">
											{{$t('Products.offer-data')}}
										</span>

										<v-btn
											icon
											@click="
												showOfferInputs = false;
												offerData.name = null;
												offerData.toDate = null;
												offerData.fromDate = null;
												offerData.discount = null;
												offerData.isPercent = false;
											"
										>
											<v-icon>mdi-close-circle-outline</v-icon>
										</v-btn>
									</div>
									<v-row dense class="ma-0">
										<!-- name -->
										<v-col :lg="useUpSellingForm ? 2 : null" md="3" sm="6" cols="12">
											<v-text-field
												v-model="offerData.name"
												:label="$t('inputs.name')"
												outlined
												dense
												hide-details
												class="rounded-medium"
												:rules="rules.required"
											></v-text-field>
										</v-col>
										
										<!-- from-date -->
										<v-col md="3" sm="6" cols="12">
											<v-menu
												ref="fromDateMenu"
												:close-on-content-click="false"
												:nudge-right="40"
												transition="scale-transition"
												offset-y
												min-width="auto"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														v-model="offerData.fromDate"
														:label="$t('inputs.from-date')"
														prepend-inner-icon="mdi-calendar"
														outlined
														dense
														class="rounded-small"
														hide-details
														:rules="rules.required"
														readonly
														clearable
														v-bind="attrs"
														v-on="on"
													></v-text-field>
												</template>
												<v-date-picker
													v-model="offerData.fromDate"
													@input="$refs.fromDateMenu.save(offerData.fromDate)"
												></v-date-picker>
											</v-menu>
										</v-col>
										
										<!-- to-date -->
										<v-col md="3" sm="6" cols="12">
											<v-menu
												ref="toDateMenu"
												:close-on-content-click="false"
												:nudge-right="40"
												transition="scale-transition"
												offset-y
												min-width="auto"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														v-model="offerData.toDate"
														:label="$t('inputs.from-date')"
														prepend-inner-icon="mdi-calendar"
														outlined
														dense
														class="rounded-small"
														hide-details
														readonly
														clearable
														:rules="[...rules.required, val => moment(val).isAfter(offerData.fromDate)]"
														v-bind="attrs"
														v-on="on"
													></v-text-field>
												</template>
												<v-date-picker
													v-model="offerData.toDate"
													@input="$refs.toDateMenu.save(offerData.toDate)"
												></v-date-picker>
											</v-menu>
										</v-col>
										
										<!-- discount -->
										<v-col :lg="useUpSellingForm ? 2 : null" md="3" sm="6" cols="12">
											<v-text-field
												v-model.number="offerData.discount"
												:label="$t('inputs.discount')"
												outlined
												dense
												hide-details
												class="rounded-medium"
												:rules="rules.requiredAsNumber"
												:append-icon="offerData.isPercent ? '%' : 'mdi-cash-multiple'"
												@click:append="offerData.isPercent = !offerData.isPercent"
											></v-text-field>
										</v-col>

										<!-- apply on up-selling -->
										<v-col v-if="useUpSellingForm" lg="2" md="3" sm="6" cols="12">
											<v-switch
												v-model="offerData.applyOnUpSelling"
												:label="$t('inputs.apply-on-up-selling')"
												hide-details
												class="mt-0 pt-0"
											/>
										</v-col>
									</v-row>
								</div>
							</v-expand-transition>
						</v-col>
					</template>
				</v-row>

				<!-- attributes -->
				<template v-if="useCategoryAttributes && attributesModel.length">
					<div class="text-body-1 font-weight-bold text-capitalize mb-3">
						{{$t('attributes')}}
					</div>

					<v-row
						v-for="(attributeData, index) in attributesModel"
						:key="index"
						dense
						class="mb-2"
					>
						<v-col cols="12" class="text-body-2 font-weight-bold text-capitalize">
							{{attributeData.categoryName}}
						</v-col>

						<!-- attribute input -->
						<v-col
							v-for="(attribute, i) in attributeData.attributes"
							:key="i"
							lg="4" md="6" cols="12"
						>
							<!-- attribute name -->
							<div class="d-flex align-center mb-2">
								<span class="text-capitalize me-2">{{attribute.name}}</span>
								<v-btn
									v-if="attribute.showEditor"
									icon
									small
									@click="attribute.showEditor = false"
								>
									<v-icon dense>mdi-check-circle-outline</v-icon>
								</v-btn>
							</div>

							<!-- attribute editor -->
							<text-editor
								v-if="attribute.showEditor"
								v-model="attribute.value[lang]"
								:editor-id="`attribute-editor-${i}`"
								hide-menubar
								mini-tools
								floating-toolbar
								:height="200"
								:loading="loading"
								@keydown.ctrl.enter.prevent="attribute.showEditor = false"
							></text-editor>

							<!-- attribute value preview -->
							<div
								v-else
								v-html="
									attribute.value[lang]
										? `<span class='text-editor-content__label--active'>
											${$t('inputs.text')}
											</span>` +
											attribute.value[lang]
										: `<span class='text-editor-content__label'>${$t('inputs.text')}</span>`
								"
								class="text-editor-content"
								@click="attribute.showEditor = true"
							></div>
						</v-col>
					</v-row>
				</template>

				<!-- up-selling form -->
				<template v-if="useUpSellingForm">
					<div class="text-body-1 font-weight-bold text-capitalize mb-3">
						{{$t('Products.up-selling-form')}}
					</div>

					<!-- form title -->
					<v-row align="center" dense class="ma-0 mb-1">
						<v-col sm="6" cols="12">
							<v-text-field
								v-model="upSellingTitle"
								:label="$t('inputs.form-title')"
								dense
								outlined
								hide-details
								:loading="loading"
								:disabled="loading || submitLoading"
								class="rounded-medium"
							></v-text-field>
						</v-col>

						<v-spacer/>

						<v-col cols="auto">
							<v-btn
								outlined
								width="70"
								class="rounded-medium"
								color="primary"
								:disabled="loading"
								@click="upSellingDialog = true"
							>
								<v-icon size="20" class="me-1">mdi-plus-circle-outline</v-icon>
								{{$t('add')}}
							</v-btn>
						</v-col>
					</v-row>

					<!-- table -->
					<v-data-table
						:headers="headers"
						:items="loading ? [] : upSellingFormEntries"
						:items-per-page="-1"
						:loading="loading"
						:loader-height="2"
						dense
						disable-sort
						hide-default-footer
						class="rounded-medium mb-3 elevation-2"
					>					
						<!-- text -->
						<template v-slot:item.text="{ item }">
							<div>{{item.text[lang]}}</div>
							<div v-if="item.cartText[lang]" class="mt-n1">
								<span class="text-caption text--secondary">
									({{$t('Products.in-cart')}}: 
									<span class="grey--text text--darken-2">{{item.cartText[lang]}}</span>)
								</span>
							</div>
						</template>

						<!-- type -->
						<template v-slot:item.type="{ value }">
							{{upSellingEntryTypes.find(c => c.id === value).name}}
						</template>
						
						<!-- options -->
						<template v-slot:item.options="{ item }">
							<div v-for="(item, i) in item.options.map(c => c.text)" :key="i">
								{{item[lang]}}
							</div>
							<span v-if="!item.options.length">-</span>
						</template>
						
						<!-- price -->
						<template v-slot:item.price="{ item }">
							<div v-for="(item, i) in item.options.map(c => c.price)" :key="i">{{item}}</div>
							<span v-if="!item.options.length">{{item.price || '-'}}</span>
						</template>

						<!-- is-required -->
						<template v-slot:item.isRequired="{ value }">
							<v-icon v-if="value">mdi-check-circle-outline</v-icon>
						</template>

						<!-- actions -->
						<template v-slot:item.actions="{ item, index }">
							<div
								class="d-flex justify-center align-center"
								@dragover.prevent
								@drop="getDataOnDrop($event, index, upSellingFormEntries)"
							>
								<!-- edit -->
								<tooltip :text="$t('tooltip.edit')">
									<v-btn
										color="blue darken-4"
										dark
										small
										class="ms-1 px-1 flex-shrink-1"
										min-width="20px"
										@click="fillEntryEdit(item)"
									><v-icon>mdi-pencil</v-icon></v-btn>
								</tooltip>

								<!-- delete -->
								<tooltip :text="$t('tooltip.delete')">
									<v-btn
										color="red darken-1"
										dark
										small
										class="ms-1 px-1"
										min-width="20px"
										@click="
											$route.params.id && !item.isNew
												? deletedUpSellingIds.push(item.id)
												: null;
											upSellingFormEntries.splice(index, 1);
										"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</tooltip>

								<!-- change-order -->
								<tooltip v-if="false" :text="$t('tooltip.change-order')">
									<div
										draggable
										@dragstart="setDataOnDrag($event, index)"
									>
										<v-icon>mdi-drag-vertical</v-icon>
									</div>
								</tooltip>
							</div>
						</template>
					</v-data-table>
				</template>

				<!-- SEO fields -->
				<template v-if="showSEO">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('SEO-fields')}}
					</div>
					<v-row dense>
						<!-- SEO keywords -->
						<v-col cols="12" >
							<v-text-field
								v-if="false"
								v-model="SEOkeywords"
								:label="$t('inputs.seo-keywords')"
								hide-details="auto"
								outlined
								dense
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
								hint="keywords should separated by (,) eg: keyword1, keyword2"
								@input="SEOkeywords = (SEOkeywords || '').replace(/(\w) /g, '$1, ')"
							></v-text-field>

							<v-chips-input
								v-model="SEOkeywords"
								:label="$t('inputs.seo-keywords')"
								hide-details
								outlined
								dense
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
							></v-chips-input>
						</v-col>

						<!-- SEO description -->
						<v-col cols="12">
							<v-textarea
								v-model="SEOdescription"
								:label="$t('inputs.seo-description')"
								outlined
								dense
								auto-grow
								rows="1"
								no-resize
								counter
								:hint="SEOdescription && SEOdescription.length > 155 ? 'It\'s best to keep descriptions between 155-160 characters' : null"
								persistent-hint
								:color="SEOdescription && SEOdescription.length > 155 ? 'warning' : null"
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
								@keypress.enter.prevent
								@change="SEOdescription = SEOdescription.replace(/\n/g, ' ')"
							></v-textarea>
						</v-col>
					</v-row>
				</template>

				<!-- description -->
				<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('inputs.description')}}</div>
				<text-editor
					v-model="description"
					:loading="loading"
					:error="!Boolean(description) && isSubmitted"
					:use-template-builder="allowTemplateBuilder"
				/>
			</v-col>

			<v-col cols="auto" class="d-md-block d-none px-1">
				<v-divider vertical/>
			</v-col>
			
			<!-- drop image -->
			<v-col md cols="12" class="flex-grow-1 pb-md-0">
				<div class="sticky">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('upload-images')}}
					</div>
					
					<bee-drop-img
						v-model="mainImage"
						dashed
						border-width="2"
						:border-color="isSubmitted && !mainImage ? '#f00' : '#ccc'"
						radius="11"
						height="250px"
						blurable
						clearable
						:hide-image-name="false"
						:loading="loading"
						:disabled="loading || submitLoading"
						disable-file-picker
						@remove-image="fileManagerFiles.mainImage = {}"
						@click="$eventBus.$emit('file-manager', { images: true }, (url, file) => {
							mainImage = url;
							fileManagerFiles.mainImage = file;
						})"
					>
						<template v-slot:drag-text>
							<span class="text-body-1 font-weight-medium">
								{{$t('drag-text')}}
							</span>
							<span class="text-caption mb-0">
								{{$t('supported-text')}}
							</span>
						</template>
					</bee-drop-img>
					
					<bee-drop-zone
						v-if="uploadMoreImages"
						v-model="images"
						dashed
						drop-zone-classes="pa-3"
						radius="11"
						border-width="2"
						max-height="250"
						:drop-image-props="{
							width: 70,
							height: 70
						}"
						:clear-icon="$route.params.id ? 'mdi-delete' : 'mdi-close'"
						:clear-color="$route.params.id ? 'red' : null"
						:gap-size="4"
						:drop-menu-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-max-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-left="null"
						show-add-btn
						show-drop-menu
						no-drop-replace
						class="mt-3"
						:loading="loading"
						:disabled="loading || submitLoading"
						disable-file-picker
						@remove-image="removeImage"
						@click="$eventBus.$emit('file-manager', { images: true, multiple: true }, (urls, files) => {
							images.push(...urls);
							fileManagerFiles.moreImages.push(...files);
						})"
					>
						<template v-slot:drag-text>
							<p class="mb-0">
								<span class="d-block text-body-1 font-weight-bold">
									{{$t('upload-more-image')}}
								</span>
								<span class="d-block text-caption mb-0">{{$t('drag-text')}}</span>
							</p>
						</template>
					</bee-drop-zone>
				</div>
			</v-col>
		</v-row>

		<!-- submit button -->
		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium me-2"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
		<v-btn
			color="white"
			class="rounded-medium"
			:to="{ name: backRouteName }"
			:disabled="loading || submitLoading"
		>
			{{$t('back')}}
		</v-btn>
	</v-form>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import rules from '@/helpers/validation rules'
import { sets, filters, keywords, products, orders, SEO, plans, offers, publishers } from '@/configs/routes.config';
import { useCategoryAttributes, useFileUpload, useProductCode, useInHomePage, useUploadMoreImages, useCategoryTree, useFilterTree, useCustomizedRelated, useProductQuantitative, useOrderingItems, useUpSellingForm, allowTemplateBuilder, useFiltersFor } from '@/configs/global.config';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import langsDraft from '@/mixins/langs-draft';
import { upSellingEntryType, upSellingEntryTypes } from '@/helpers/enums';
import { createObjectFromLangs, getDataOnDrop, setDataOnDrag } from '@/helpers/functions';

export default {
	name: 'Product',
	mixins: [langsDraft],
	
	data: () => ({
		// helpers
		loading: false,
		assetsLoading: false,
		submitLoading: false,
		isSubmitted: false,
		productImages: [],
		uploadedFileUrl: null,
		lang: defaultLang,
		backRouteName: products.name,
		rules,

		// configs
		showFilters: filters.show,
		showKeywords: keywords.show,
		showPublishers: publishers.show,
		showSets: sets.show,
		showPrice: orders.show,
		showPlan: plans.show,
		showOffers: offers.show,
		showSEO: SEO.show,
		showFileUpload: useFileUpload,
		showCode: useProductCode,
		showIsQuantitative: useProductQuantitative,
		uploadMoreImages: useUploadMoreImages.products,
		useCategoryAttributes,
		useCategoryTree,
		useFiltersFor,
		useFilterTree,
		useUpSellingForm,
		useInHomePage,
		useCustomizedRelated,
		multiLangInputs,
		allowTemplateBuilder,

		// file manager
		fileManagerFiles: {
			mainImage: {},
			moreImages: [],
			file: {},
		},

		// up-selling
		upSellingEntryType,
		upSellingEntryTypes,
		
		upSellingDialog: false,
		upSellingFormLang: defaultLang,
		upSellingEntryId: null,
		upSellingTitle: null,
		upSellingData: {
			text: createObjectFromLangs(),
			cartText: createObjectFromLangs(),
			type: null,
			optionsTitle: createObjectFromLangs(),
			options: [],
			isRequired: false,
			price: null,
		},
		deletedUpSellingIds: [],

		// offer data
		showOfferInputs: false,
		offerData: {
			name: null,
			toDate: null,
			fromDate: null,
			discount: null,
			isPercent: false,
			applyOnUpSelling: false
		},

		// draft for languages 
		draft: {},
		draftNames: [
			'name',
			'summary',
			'SEOkeywords',
			'SEOdescription',
			'offerData.name',
			'upSellingTitle',
			{ name: 'description', value: '' },
		],

		// data
		attributesModel: [],

		name: null,
		summary: null,
		code: null,
		categoriesIds: [],
		filterIds: [],
		keywordIds: [],
		publisherIds: [],
		date: null,
		isFeatured: false,
		inHomePage: false,
		isQuantitative: false,
		price: null,
		relatedProductsIds: [],
		suggestRelatedFromCategories: false,
		subscriptionPrice: null,
		file: null,
		SEOdescription: '',
		SEOkeywords: '',
		description: null,
		mainImage: null,
		images: [],
		upSellingFormEntries: []
	}),

	watch: {
		$route(newVal) {
			if (newVal.params.id) {
				this.loading = true;
				this.fetchProduct().finally(() => {
					this.loading = false;
				});
			}
		},

		upSellingDialog(val) {
			if (!val) {
				this.$refs.upSellingForm.reset();

				this.upSellingEntryId = null;
				this.upSellingData.text = createObjectFromLangs();
				this.upSellingData.cartText = createObjectFromLangs();
				this.upSellingData.optionsTitle = createObjectFromLangs();
				this.upSellingData.isRequired = false;
				this.upSellingData.options = [];
			} else {
				this.upSellingFormLang = this.lang;
			}
		},
	},

	computed: {
		...mapState({
			sets: state => state.sets.sets.filter(c => !c.deletedAt),
			categories: state => state.categories.categories.filter(c => !c.deletedAt),
			hierarchyCategories: state => state.categories.hierarchyCategories.filter(c => !c.deletedAt),
			filters: state => state.filters.filters.filter(c => !c.deletedAt),
			hierarchyFilters: state => state.filters.hierarchyFilters.filter(c => !c.deletedAt),
			keywords: state => state.keywords.keywords.filter(c => !c.deletedAt),
			publishers: state => state.publishers.publishers.filter(c => !c.deletedAt),
			products: state => state.products.products.filter(c => !c.deletedAt),
		}),

		...mapGetters({
			getSetById: 'sets/getSetById',
			getCategoryById: 'categories/getCategoryById',
		}),

		headers() {
			const headers = [
				{ text: this.$t('headers.text'), value: 'text', width: 300 },
				{ text: this.$t('headers.type'), value: 'type' },
				{ text: this.$t('headers.options'), value: 'options' },
				{ text: this.$t('headers.price'), value: 'price' },
				{ text: this.$t('headers.required'), value: 'isRequired', align: 'center', width: 40 },
				{ text: this.$t('headers.actions'), value: 'actions', width: 120, align: 'center' },
			];
			for (let i = 0; i < headers.length; i++) {
				headers[i].class = 'px-2';
				headers[i].cellClass = 'px-2';
			}
			return headers
		}
	},

	methods: {
		getCategoryItemText(item) {
			if (this.sets.length && this.showSets) {
				const setsNames = [];
				item.setsIds.forEach(id => {
					const set = this.getSetById(id);
					if (set) setsNames.push(set.name);
				});
				return (
					this.getCategoryById(item.id).name +
					(setsNames.length ? ' - ' + setsNames.join(',') : '')
				)
			} else {
				return item.name
			}
		},

		showFile(url) { window.open(url); },

		initializeAttributesModel() {
			if (!useCategoryAttributes) return;

			const modifiedCatagoriesIds = []
			const attributesCategoriesIds = this.attributesModel.map(c => c.categoryId);
			this.categoriesIds.forEach(id => {
				if (!attributesCategoriesIds.includes(id)) modifiedCatagoriesIds.push(id)
			})
			attributesCategoriesIds.forEach(id => {
				if (!this.categoriesIds.includes(id)) modifiedCatagoriesIds.push(id)
			})

			modifiedCatagoriesIds.forEach(id => {
				const category = this.getCategoryById(id);
				if (attributesCategoriesIds.includes(category.id)) {
					const index = this.attributesModel.findIndex(c => c.categoryId === category.id);
					this.attributesModel.splice(index, 1);
				} else {
					if (category.attributes.length) {
						const attributes = [];
						category.attributes.forEach(attribute => {
							attributes.push({
								id: attribute.id,
								name: attribute.name,
								image: attribute.image.url,
								value: createObjectFromLangs(),
								showEditor: false
							})
						})
						
						this.attributesModel.push({
							categoryId: category.id,
							categoryName: this.getCategoryItemText(category),
							attributes
						})
					}
				}
			})
		},

		// up-selling
		saveEntry() {
			if (!this.$refs.upSellingForm.validate()) return;
			
			const obj = {
				text: { ...this.upSellingData.text },
				cartText: { ...this.upSellingData.cartText },
				type: this.upSellingData.type,
				optionsTitle: { ...this.upSellingData.optionsTitle },
				options: this.upSellingData.options.map(c => ({ text: { ...c.text }, price: c.price })),
				price: this.upSellingData.price,
				isRequired: this.upSellingData.isRequired,
			}
			if (this.upSellingEntryId) {
				const index = this.upSellingFormEntries.findIndex(c => c.id === this.upSellingEntryId);
				this.upSellingFormEntries[index].text = obj.text;
				this.upSellingFormEntries[index].cartText = obj.cartText;
				this.upSellingFormEntries[index].type = obj.type;
				this.upSellingFormEntries[index].optionsTitle = obj.optionsTitle;
				this.upSellingFormEntries[index].options = obj.options;
				this.upSellingFormEntries[index].price = obj.price;
				this.upSellingFormEntries[index].isRequired = obj.isRequired;
				if (this.$route.params.id) this.upSellingFormEntries[index].isUpdated = true;

				this.upSellingEntryId = null;
				this.upSellingDialog = false;
			} else {
				this.upSellingFormEntries.push({
					id: Date.now(),
					isNew: true,
					...obj
				});
			}
			this.upSellingFormLang = defaultLang;
			this.upSellingData.text = createObjectFromLangs();
			this.upSellingData.cartText = createObjectFromLangs();
			this.upSellingData.optionsTitle = createObjectFromLangs();
			this.$refs.upSellingForm.reset();
		},

		fillEntryEdit(entry) {
			this.upSellingEntryId = entry.id;
			this.upSellingData.text = { ...entry.text };
			this.upSellingData.cartText = { ...entry.cartText };
			this.upSellingData.type = entry.type;
			this.upSellingData.optionsTitle = { ...entry.optionsTitle };
			this.upSellingData.price = entry.price;
			this.upSellingData.isRequired = entry.isRequired;

			entry.options.forEach(c => {
				this.upSellingData.options.push({ id: c.id, text: { ...c.text }, price: c.price })
			});
			this.upSellingDialog = true;
		},

		async submitUpSellingEntires(productId) {
			const promises = [];
			if (this.upSellingFormEntries.length) {
				const newUpSellingEntries = this.upSellingFormEntries.filter(c => c.isNew);
				const updateUpSellingEntries = this.upSellingFormEntries.filter(c => c.isUpdated);
				
				// saving new up-selling
				const creatingPromises = [];
				newUpSellingEntries.forEach(entry => {
					if (entry.text[defaultLang] && entry.cartText[defaultLang]) {
						creatingPromises.push(
							this.$store.dispatch('products/createUpSelling', {
								id: entry.id,
								productId: productId,
								text: entry.text[defaultLang],
								cartTitle: entry.cartText[defaultLang],
								type: entry.type,
								price: entry.price,
								optionTitle: entry.optionsTitle[defaultLang],
								options: entry.options.map(option => ({
									text: option.text[defaultLang],
									price: option.price
								})),
								isRequired: entry.isRequired,
								acceptLanguage: defaultLang
							}).then((data) => {
								updateUpSellingEntries.push({
									...entry,
									id: data.id,
									createdLang: defaultLang,
								});
							})
						)
					}
				});
				await Promise.all(creatingPromises).catch((e) => { throw e });

				// updating existed up-selling or updating new up-selling for other languages
				langs.filter(c => multiLangInputs || c.key === defaultLang).forEach(c => {
					updateUpSellingEntries.filter(e => !e.createdLang || e.createdLang !== c.key)
						.forEach(entry => {
							if (entry.text[c.key] && entry.cartText[c.key]) {
								promises.push(
									this.$store.dispatch('products/updateUpSelling', {
										id: entry.id,
										productId: productId,
										text: entry.text[c.key],
										cartTitle: entry.cartText[c.key],
										type: entry.type,
										price: entry.price,
										optionTitle: entry.optionsTitle[c.key],
										options: entry.options.map(option => ({
											text: option.text[c.key],
											price: option.price
										})),
										isRequired: entry.isRequired,
										acceptLanguage: c.key
									})
								)
							}
						})
				});

				// deleting up-selling
				this.deletedUpSellingIds.forEach(id => {
					promises.push(this.$store.dispatch('products/deleteUpSelling', { id }));
				})
			}
			return Promise.all(promises);
		},

		// file manager
		fileCallBack(url, file) {
			this.file = new File([], file.name);
			this.fileManagerFiles.file = file;
		},

		// submit
		async submit() {
			this.isSubmitted = true;

			if (this.$refs.form.validate() && this.mainImage && this.description) {
				this.submitLoading = true;
				// more images
				const images = this.images.filter(c => typeof c !== 'string');

				const promises = [];
				let imagesPromise = null;
				let itemData = null;

				const submitRequest = (
					isDefaultOne,
					{ name, summary, upSellingTitle, description, SEOkeywords, SEOdescription, lang },
					id = this.$route.params.id
				) => {
					const actionName = id ? 'update' : 'create';
					return this.$store.dispatch(`products/${actionName}`, {
						id: id,
						name: name,
						summary: summary,
						code: this.code,
						isFeatured: this.isFeatured,
						inHomePage: this.inHomePage,
						isQuantitative: this.isQuantitative,
						suggestRelated: this.suggestRelatedFromCategories,
						price: this.price,
						secondPrice: this.subscriptionPrice,
						dateOfPublication: this.date,
						upSellingTitle: upSellingTitle,
						description: description,
						categoryIds: this.categoriesIds,
						filterIds: this.filterIds,
						keywordIds: this.keywordIds,
						publisherIds: this.publisherIds,
						attributes: this.attributesModel.flatMap(
							c => c.attributes.map(e => ({ id: e.id, value: e.value[lang] }))
						),
						relatedProductIds: this.relatedProductsIds,
						image:
							isDefaultOne && typeof this.mainImage !== 'string'
								? this.mainImage
								: null,
						imagePath:
							isDefaultOne && typeof this.mainImage === 'string'
								? this.fileManagerFiles.mainImage.publicPath
								: null,
						imageName:
							isDefaultOne && typeof this.mainImage === 'string'
								? this.fileManagerFiles.mainImage.fileName
								: null,
						// file: this.file && this.file.size ? this.file : null,
						filePath:
							isDefaultOne && this.file && this.fileManagerFiles.file
								? this.fileManagerFiles.file.publicPath
								: null,
						fileName:
							isDefaultOne && this.file && this.fileManagerFiles.file
								? this.fileManagerFiles.fileName
								: null,
						descriptionSeo: SEOdescription,
						keywordsSeo: SEOkeywords,
						acceptLanguage: lang
					}).then((data) => {
						// upload the other images
						if (isDefaultOne && (images.length || this.fileManagerFiles.moreImages.length)) {
							imagesPromise = this.$store.dispatch('products/uploadImages', {
								id: data.id,
								images,
								imagesPaths: this.fileManagerFiles.moreImages.map(c => c.publicPath),
								imagesNames: this.fileManagerFiles.moreImages.map(c => c.fileName),
							});
						}
						return data;
					})
				}

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang)
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const name = this.draft.name[lang];
					const summary = this.draft.summary[lang];
					const SEOkeywords = this.draft.SEOkeywords[lang];
					const SEOdescription = this.draft.SEOdescription[lang];
					const description = this.draft.description[lang];
					const upSellingTitle = this.draft.upSellingTitle[lang];

					const dataDueLang = { name, summary, SEOkeywords, SEOdescription, description, upSellingTitle, lang };

					if (name && description) {
						if (index === 0) {
							await submitRequest(index === 0, dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(false, dataDueLang, itemData.id));
						}
					};
				}

				const upSellingPromise = await this.submitUpSellingEntires(itemData.id);
				upSellingPromise.push(upSellingPromise);

				const successHandler = () => {
					const message =
						this.$route.params.id
							? this.$t('$-is-updated-successfully', { name: this.$t('Products.product') })
							: this.$t('$-is-created-successfully', { name: this.$t('Products.product') });
					this.$eventBus.$emit('show-snackbar', false, message);
					this.$router.push({ name: products.name });
				}
				const errorHandler = () => {
					if (itemData) {
						this.$router.replace({
							name: this.$route.name,
							params: { slug: itemData.slug, id: itemData.id }
						});
					}
				}

				Promise.all(promises).then(() => {
					if (!imagesPromise) successHandler();
					else {
						this.$eventBus.$emit('show-snackbar', false, this.$t('please-wait-uploading-the-images'));
						imagesPromise.catch(errorHandler).then(successHandler);
					}
				}).catch(
					errorHandler
				).finally(() => {
					if (!imagesPromise) this.submitLoading = false;
					else imagesPromise.finally(() => { this.submitLoading = false });
				})
			}
		},

		// FIXME show dialog to confirm remove
		removeImage(image) {
			if (this.$route.params.id) {
				const imageId = this.productImages.find(c => c.url === image)?.id;
				
				if (imageId) {
					this.$store.dispatch('products/deleteImage', {
						id: this.$route.params.id,
						imageId: imageId
					})
				}
			}

			const fileManagerImageId = this.fileManagerFiles.moreImages.find(c => c.url === image)?.id;
			if (fileManagerImageId) {
				const imageIndex = this.fileManagerFiles.moreImages.findIndex(c => c.id === fileManagerImageId);
				this.fileManagerFiles.moreImages.splice(imageIndex, 1);
			}
		},

		fetchProduct() {
			const promises = [];

			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('products/fetchBySlug', {
						slug: this.$route.params.slug,
						acceptLanguage: c.key
					}).then((data) => {
						if (index === 0) {
							this.categoriesIds = data.categoriesIds;
							this.filterIds = data.filterIds;
							this.keywordIds = data.keywordsIds;
							this.publisherIds = data.publisherIds;
							this.relatedProductsIds = data.relatedProductIds;
							this.date = data.dateOfPublication ? moment(data.dateOfPublication).format('YYYY-MM-DD') : null;
							this.isFeatured = data.isFeatured;
							this.inHomePage = data.inHomePage;
							this.isQuantitative = data.isQuantitative;
							this.suggestRelatedFromCategories = data.suggestRelated;
							this.price = data.price;
							this.subscriptionPrice = data.secondPrice;
							this.file = data.files[0] ? new File([], this.$t('messages.there-is-uploaded-file')) : null;
							this.uploadedFileUrl = data.files[0] ? data.files[0].url : null;
							this.mainImage = data.primaryImage.url;
							this.images = data.images.map(c => c.url).filter(c => c !== data.primaryImage.url);

							this.productImages = data.images.filter(c => c !== data.primaryImage.url);

							this.initializeAttributesModel();
						}

						// attributes
						this.categoriesIds.forEach(categoryId => {
							const attributeModel = this.attributesModel.find(c => c.categoryId === categoryId);
							if (attributeModel) {
								data.attributes.forEach(attr => {
									const attribute = attributeModel.attributes.find(e => e.id === attr.id);
									if (attribute) attribute.value[c.key] = attr.value;
								})
							}
						});

						// up-selling
						if (useUpSellingForm) {
							// initialling up-selling entires for first language
							if (!this.upSellingFormEntries.length) {
								this.upSellingFormEntries = data.upSelling.map((entry, index) => ({
									id: entry.id || index,
									text: createObjectFromLangs({ [c.key]: entry.text }),
									cartText: createObjectFromLangs({ [c.key]: entry.cartTitle }),
									type: entry.type,
									optionsTitle: createObjectFromLangs({ [c.key]: entry.optionTitle }),
									options: entry.options.map((option, i) => ({
										id: option.id || i,
										text: createObjectFromLangs({ [c.key]: option.text }),
										price: option.price
									})),
									isRequired: entry.isRequired,
									price: entry.price
								}));
							
							// filling texts in up-selling for other languages
							} else {
								for (let i = 0; i < data.upSelling.length; i++) {
									this.upSellingFormEntries[i].text[c.key] = data.upSelling[i].text;
									this.upSellingFormEntries[i].cartText[c.key] = data.upSelling[i].cartTitle;
									this.upSellingFormEntries[i].optionsTitle[c.key] = data.upSelling[i].optionTitle;
									for (let j = 0; j < data.upSelling[i].options.length; j++) {
										this.upSellingFormEntries[i].options[j][c.key] =
											data.upSelling[i].options[j].text;
									}
								}
							}
						}

						this.draft.name[c.key] = data.name;
						this.draft.summary[c.key] = data.summary;
						this.draft.upSellingTitle[c.key] = data.upSellingTitle;
						this.draft.description[c.key] = data.description;
						this.draft.SEOkeywords[c.key] = data.keywordsSeo;
						this.draft.SEOdescription[c.key] = data.descriptionSeo;
					})
				)
			})

			return Promise.all(promises).finally(() => {
				this.name = this.draft.name[this.lang];
				this.summary = this.draft.summary[this.lang];
				this.upSellingTitle = this.draft.upSellingTitle[this.lang];
				this.description = this.draft.description[this.lang];
				this.SEOkeywords = this.draft.SEOkeywords[this.lang];
				this.SEOdescription = this.draft.SEOdescription[this.lang];
			})
		},

		// helpers
		getDataOnDrop,
		setDataOnDrag,
		createObjectFromLangs,
		moment
	},

	async created() {
		const promises = [
			!this.sets.length ? this.$store.dispatch('sets/fetchAll') : null,
			!this.categories.length
				? Promise.all([
					this.$store.dispatch('categories/fetchAll'),
					useCategoryTree ? this.$store.dispatch('categories/fetchAllAsHierarchy') : null,
				])
				: null,
			!this.filters.length
				? Promise.all([
					this.$store.dispatch('filters/fetchAll'),
					useFilterTree ? this.$store.dispatch('filters/fetchAllAsHierarchy') : null
				])
				: null,
			!this.keywords.length ? this.$store.dispatch('keywords/fetchAll') : null,
			!this.publishers.length && this.showPublishers ? this.$store.dispatch('publishers/fetchAll') : null,
			useCustomizedRelated && !this.products.length
				? this.$store.dispatch('products/fetchAll', { userOrder: useOrderingItems })
				: null,
		];

		if (this.$route.params.id) {
			this.loading = true;

			if (useCategoryAttributes) {
				await Promise.all(promises);
				await this.fetchProduct();
				this.loading = false;
			} else {
				promises.push(this.fetchProduct());
				Promise.all(promises).finally(() => { this.loading = false; });
			}
		} else {
			this.assetsLoading = true;
			Promise.all(promises).finally(() => {
				this.assetsLoading = false;
			})
		}
	},

	mounted() {
		// setTimeout(() => {
		// this.mainImage = 'https://www.shutterstock.com/image-photo/zorse-zebra-horse-hybrid-background-260nw-1496520587.jpg';

		// this.images = [
		// 	'https://www.shutterstock.com/image-photo/zorse-zebra-horse-hybrid-background-260nw-1496520587.jpg',
		// 	'https://www.google.com/url?sa=i&url=https%3A%2F%2Fpixabay.com%2Fimages%2Fsearch%2Fnature%2F&psig=AOvVaw0vejuzECsWYXoQiC0J8FZd&ust=1674212169822000&source=images&cd=vfe&ved=0CA0QjRxqFwoTCOiypIXQ1PwCFQAAAAAdAAAAABAD',
		// 	'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.commentcamarche.net%2Fapplis-sites%2Fservices-en-ligne%2F729-faire-une-recherche-a-partir-d-une-image-sur-google%2F&psig=AOvVaw0vejuzECsWYXoQiC0J8FZd&ust=1674212169822000&source=images&cd=vfe&ved=0CA0QjRxqFwoTCOiypIXQ1PwCFQAAAAAdAAAAABAQ',
		// 	'https://imgv3.fotor.com/images/blog-cover-image/part-blurry-image.jpg',
		// ]
		// }, 500)
	}
};
</script>

<style lang="scss">
</style>